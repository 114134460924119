<template>
    <div class="row">
        <h2 class="section-heading m-b-md"> {{$t('global.client', {text: 'Statistics'})}}</h2>
        <MessageCard :alertType="'indicator-info'" :alertTitle="$t('claims.new.comments')" :alertText="$t('stats.global.alert_message')" class="m-b-md"/>
        <div class="col-xl-4 m-b-md">
            <div class="form-group">
                <label class="form-label" for="site"> {{$t('global.select', {text: Client})}}</label>
                <select class="form-select" v-model="selectedClient">
                    <!-- When selecting from any other site to all available sites, selectedSite id doesn't change -->
                    <!-- <option value="" selected disabled> Select a client </option> -->
                    <option v-for="client in clients" :value="client.id" :key="client.id">{{client.name}}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <stats-card :title="$t('stats.one.title')" :statsData="stats.escalated"></stats-card>
            <stats-card :title="$t('stats.two.title')" :statsData="stats.driveoffFirstLetter"></stats-card>
            <stats-card :title="$t('stats.three.title')" :statsData="stats.driveoffSecondLetter"></stats-card>
            <stats-card :title="$t('stats.four.title')" :statsData="stats.nmopDebtCollection"></stats-card>
            <stats-card :title="$t('stats.five.title')" :statsData="stats.driveoffDebtCollection"></stats-card>
            <stats-card :title="$t('stats.six.title')" :desc="$t('stats.six.desc')" :statsData="stats.blacklist"></stats-card>
        </div>

    </div>
  
</template>

<script>
import siteService from '../../services/site.service';
import MessageCard from '../../components/MessageCard.vue';
import StatsCard from '../../components/StatsCard.vue';
import {DATES} from '../../../src/constants/constants';
import statsService from '../../services/stats.service';

export default {
    name: 'ClientStatistics',
    components: {
        MessageCard,
        StatsCard
    },
    mounted() {
        this.clients = this.$store.state.auth.user.clients;
        this.selectedClient = this.clients[0].id;
        this.getEscalated();
        this.getDriveoffFirstLetter();
        this.getDriveoffSecondLetter();
        this.getNmopCollection();
        this.getdriveoffCollection();
        this.getBlacklistCount();
    },
    data() {
        return {
            clients: [],
            selectedClient: -1,
            stats: {
                escalated: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffFirstLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffSecondLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                nmopDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                blacklist: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
            },
            dates: DATES
        }
    },
    methods: {
        getClients() {
            siteService.getSites(1, this.$store.state.auth.user.selectedClient, {name:null, postcode: null})
            .then(response => {
                this.sites = response.data.sites;
            })
        },
        getEscalated() { 
            for(let stat in this.stats.escalated) {
                statsService.getEscalated(this.selectedClient, null, this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.escalated[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load escalated claims count'), error);
                })
            }
        },
        getDriveoffFirstLetter() {
            for(let stat in this.stats.driveoffFirstLetter) {
                statsService.getLetterCount(this.selectedClient, null, 'driveoffnotice', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffFirstLetter[stat] = response.data.claims.length;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff first letter count'), error);
                })
            }
        },
        getDriveoffSecondLetter() {
            for(let stat in this.stats.driveoffSecondLetter) {
                statsService.getLetterCount(this.selectedClient, null, 'driveoffsecond', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffFirstLetter[stat] = response.data.claims.length;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            }
        },
        getNmopCollection() {
            for(let stat in this.stats.nmopDebtCollection) {
                statsService.getNmopCollection(this.selectedClient, null, 'nmop', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.nmopDebtCollection[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            }
        },
        getdriveoffCollection() {
           for(let stat in this.stats.driveoffDebtCollection) {
                statsService.getNmopCollection(this.selectedClient, null, 'driveoff', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffDebtCollection[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            } 
        },
        getBlacklistCount() {
            for(let stat in this.stats.blacklist) {
                statsService.getBlacklistCount(this.selectedClient, null, this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.blacklist[stat] = response.data.blacklist_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load blacklisted vehicle count'), error);
                })
            }
        }
    },
    computed: {
        site_name: function() {
            if(this.selectedSite === -1) return ''; 
            return this.sites.filter(site => site.id === this.selectedSite)[0].name;
        }
    }
}
</script>

<style>

</style>